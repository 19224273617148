/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

/***
 * GERAL
 ***/
$(function() {
    var $win = $(window);

    $('header .menuToggle').click(function(event) {
        event.preventDefault();
        $('header nav').slideToggle();
        $('html').toggleClass('open-menu');
    });

    $win.resize(function(event) {
        $('html').removeClass('open-menu');

        if ($win.width() <= 768) {
            $('header nav').css('display', 'none');
        } else {
            $('header nav').css('display', 'inline-block');
        }
    });


    // sticky menu
    var controller = new ScrollMagic.Controller();

    new ScrollMagic.Scene({triggerElement: 'header .nav', triggerHook: 0})
        .setPin('header .nav')
        .addTo(controller);

    // owl carousel
    $('.banner-topo .owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : true,
        autoplayTimeout : 3000,
        dots            : false,
        animateOut      : 'fadeOut',
    });

    $('.banner-home .owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : true,
        autoplayTimeout : 5000,
        dots            : true,
        animateOut      : 'fadeOut',
    });

    $('.produtos .owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : true,
        autoplayTimeout : 5000,
        dots            : false,
        nav             : true,
        navText         : ['<i class="icon-left-open"></i>','<i class="icon-right-open"></i>'],
    });


    // tirando autocomplete
    $('.wpcf7-form').attr('autocomplete', 'off').attr('autosuggest', 'off');


    // Magnific Popup
    $('.galeria').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
              enabled:true
            }
        });
    });


    // Input masks
    $("input.cpf").mask("999.999.999-99");
    $("input.cnpj").mask("99.999.999/9999-99");
    $("input.data").mask("99/99/9999");
    $("input.cep").mask("99999-999");
    $("input.hora").mask("99:99");
    $("input.telefone").focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');

    // WOW
    wow = new WOW({
        offset: 10,
    });

    wow.init();



    // ScrollMagic
    var controller = new ScrollMagic.Controller(),
        height     = $('.box-produto-nacional').height() / 1.5,
        hProduto   = $('.single-produtos .detalhes').height() + 150,
        descricao  = $('.single-produtos .destaque .texto p.descritivo').height();

        if ($win.width() > 1400) {
            var total   = $('.single-produtos .destaque').height() - $('.single-produtos .destaque .conteudo').height() + $('.single-produtos .detalhes h1').height() + descricao - 100;
        } else {
    		var total   = $('.single-produtos .destaque').height() - $('.single-produtos .destaque .conteudo').height() + $('.single-produtos .detalhes h1').height() + descricao - 60;
        }

        // produto nacional
        new ScrollMagic.Scene({
                duration: height,
                triggerElement: ".box-produto-nacional",
                triggerHook: 0.5,
            })
            .setTween(".box-produto-nacional img", {opacity: '1'})
            .addTo(controller);


        if ($win.width() > 992) {

            var $pin = $('.pin');

            if ($pin.length > 0) {

                new ScrollMagic.Scene({triggerHook: 0, duration: 500})
                    .setPin('.pin')
                    .addTo(controller);

                new ScrollMagic.Scene({duration: 400})
                    .setTween(".animacao h1.title span.left", {marginRight: '160px'})
                    .addTo(controller);

                new ScrollMagic.Scene({duration: 400})
                    .setTween(".animacao h1.title span.right", {marginLeft: '160px'})
                    .addTo(controller);

                new ScrollMagic.Scene({duration: 400, offset: 100})
                    .setTween(".animacao .texto-anim", {opacity: 1})
                    .addTo(controller);

            }

        }

        if ($win.width() > 768) {
        var descricaoM = $('.single-produtos .detalhes .texto h1').css('margin-bottom', descricao + 80);

            // single produto Imagem tween
            var tween = TweenMax.to(".single-produtos .destaque .imagem", 1);

            // single produto Imagem scene
            var scene = new ScrollMagic.Scene({triggerElement: ".trigger", triggerHook: 0.5, duration: hProduto})
                            .setTween(tween)
                            .setPin(".single-produtos .destaque .imagem", {pushFollowers: false})
                            .addTo(controller);

            // single produto Texto tween
            var tween = TweenMax.to(".single-produtos .destaque .texto p.descritivo", 1);

            // single produto Texto scene
            var scene = new ScrollMagic.Scene({triggerElement: ".trigger", triggerHook: 0.5, duration: total})
                            .setTween(tween)
                            .setPin(".single-produtos .destaque .texto p.descritivo", {pushFollowers: false})
                            .addTo(controller);
        }

    // Gmap3
    if ($('.map').length > 0) {
        $('.map')
            .gmap3({
                center:[-21.820037, -48.154282],
                zoom: 16,
                navigationControl: false,
                scrollwheel: false,
                streetViewControl: false,
                mapTypeControl: false,
            })
            .marker([
                {
                    position:[-21.820037, -48.154282],
                },
            ]);
    }

    // Perguntas
    $('body').on('click', '.perguntas .pergunta .collapse-box .link', function(event) {
        var target = $(this).data('target');
        
        $('#collapse').collapse('close', target);

        $(target).animatescroll({
            scrollSpeed :500,
            padding     : 200
        });
    });

});